import { styled } from 'styled-components';

export const S = {
  ProfileImage: styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover; // 이미지가 잘리지 않도록 설정
    // background-color: #d6d6d6;
    border-radius: 100%;
  `,
};
