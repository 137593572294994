import React from 'react';
import FaqService from '../components/customerSide/faqService/FaqService';

const CustomerFaq = () => {
  return (
    <>
      <FaqService />
    </>
  );
};

export default CustomerFaq;
