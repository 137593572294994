import React from 'react';
import Data from '../components/adminSide/adminData/Data';

const AdminData = () => {
  return (
    <div>
      <Data />
    </div>
  );
};

export default AdminData;
