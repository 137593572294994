import { styled } from 'styled-components';

export const H = {
  BodyWrapper: styled.div`
    overflow: hidden;
    // padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
    color: #333;
  `,
};
