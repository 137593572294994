import React from 'react';
import MailingService from '../components/customerSide/mailingService/MailingService';

const CustomerMailing = () => {
  return (
    <div>
      <MailingService />
    </div>
  );
};

export default CustomerMailing;
