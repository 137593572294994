import React from 'react';
import FanletterService from '../components/customerSide/fanletterService/FanletterService';

const CustomerFanletter = () => {
  return (
    <>
      <FanletterService />
    </>
  );
};

export default CustomerFanletter;
