import React from 'react';
import ChallengeService from '../components/customerSide/challengeService/ChallengeService';

const CustomerChallenge = () => {
  return (
    <>
      <ChallengeService />
    </>
  );
};

export default CustomerChallenge;
